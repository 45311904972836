import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Row, Col, Typography } from 'antd';
import useFormatMsg from 'hooks/translations/useFormatMsg';
import {
  CheckOutlined,
  CheckCircleOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { useAppState } from 'services/providers/AppState';
import appConfig from '../app.config';
const { Title, Paragraph, Text } = Typography;

export default function Landing() {
  const history = useHistory();
  const msg = useFormatMsg('app.landing.BACKMARKET');
  const { partner, customerPortalConfig } = useAppState();

  const onClickLogin = () => {
    history.push('/login');
  };
  const onClickRegister = () => {
    history.push('/register');
  };

  return (
    <>
      <Wrap>
        <Col xs={24} sm={12}>
          <Card style={{ textAlign: 'left' }}>
            <Title style={{ marginTop: 0, wordBreak: 'break-word' }}>
              {msg('caption')}
            </Title>
            <div
              style={{ marginBottom: '1em' }}
              dangerouslySetInnerHTML={{
                __html: msg(`description`),
              }}
            />
            <div className="btn-group cap">
              {!customerPortalConfig?.configuration?.landing
                ?.hide_buttonRegister && (
                <Button
                  type="primary"
                  onClick={onClickRegister}
                  id="button_register"
                >
                  {msg('btn.register')}
                </Button>
              )}
              {!customerPortalConfig?.configuration?.landing
                ?.hide_buttonLogin && (
                <Button
                  type="primary"
                  ghost
                  onClick={onClickLogin}
                  id="button_login"
                >
                  {msg('btn.login')}
                </Button>
              )}
            </div>
          </Card>
        </Col>
        <ImgWrapper xs={24} sm={12}>
          <Img src={appConfig[partner].landingImg} />
        </ImgWrapper>
      </Wrap>
      {!customerPortalConfig?.configuration?.landing?.hide_how_it_works && (
        <>
          <Row>
            <Title className={'ant-col-xs-24'} level={2}>
              {msg('how_works')}
            </Title>
          </Row>
          <Row
            style={{
              position: 'relative',
              padding: '2rem 0',
              marginBottom: '4rem',
            }}
          >
            {[1, 2, 3, 4].map(i => (
              <Col
                md={24}
                lg={5}
                key={i}
                style={{ zIndex: 1, paddingRight: '2rem' }}
              >
                <Title level={4}>{`${i}. ${msg(`how_works_title${i}`)}`}</Title>
                <HowWorksElement
                  dangerouslySetInnerHTML={{
                    __html: msg(`how_works_text${i}`),
                  }}
                />
              </Col>
            ))}
            <HowWorksBackground />
          </Row>
        </>
      )}
      {!customerPortalConfig?.configuration?.landing?.hide_advantages && (
        <Row style={{ marginTop: '3rem' }}>
          <Col lg={24} xl={12}>
            <Row>
              <Title style={{ marginBottom: '2rem' }} level={2}>
                {msg('customer_portal_advantages')}
              </Title>
              {[1, 2].map(i => (
                <ListItem xs={24} key={i}>
                  <CheckOutlined />
                  <Text>{msg(`customer_portal_advantage${i}`)}</Text>
                </ListItem>
              ))}
              {!customerPortalConfig?.configuration?.main
                ?.hide_extra_request && (
                <ListItem xs={24} key={3}>
                  <CheckOutlined />
                  <Text>{msg(`customer_portal_advantage${3}`)}</Text>
                </ListItem>
              )}
            </Row>
          </Col>
          <AdvantageListClaim lg={24} xl={12}>
            <AdvantageListBackground className="advantageListBackground" />
            <AdvantageListClaimStatus>
              <Title
                className={'ant-col-xs-16'}
                style={{ marginBottom: 0 }}
                level={4}
              >
                {msg('claim_status')}
              </Title>
              <ProcessingInfo xs={8}>
                <Text>{msg('processing')}</Text>
              </ProcessingInfo>
              <ClaimStepsItem xs={8}>
                <CheckCircleOutlined />
                <Text>{msg('declared')}</Text>
              </ClaimStepsItem>
              <ClaimStepsItem xs={8}>
                <RightOutlined className={'arrow'} />
                <Text className={'step-number active'}>2</Text>
                <Text>{msg('verificated')}</Text>
              </ClaimStepsItem>
              <ClaimStepsItem xs={8}>
                <RightOutlined className={'arrow'} />
                <Text className={'step-number disabled'}>3</Text>
                <Text className={'disabled-label'}>{msg('resolved')}</Text>
              </ClaimStepsItem>
              <ClaimStepsBar>
                <div />
              </ClaimStepsBar>
            </AdvantageListClaimStatus>
          </AdvantageListClaim>
        </Row>
      )}
    </>
  );
}

const Wrap = styled(Row)`
  @media (min-width: 1441px) {
    align-items: center;
    margin-bottom: 2rem;
  }
  @media (min-width: 993px) and (max-width: 1440px) {
    min-height: calc(450px - 3rem);
    margin-bottom: 1rem;
  }
  @media (max-width: 992px) {
    margin-bottom: 3rem;
  }
`;
const ImgWrapper = styled(Col)`
  text-align: right;

  @media (max-width: 1440px) {
    position: inherit;
  }

  @media (max-width: 992px) {
    display: none;
  }
`;
const Img = styled('img')`
  position: absolute;
  top: 0;
  right: 0;
  max-height: 500px;
  object-fit: cover;
  z-index: 0;
  border-bottom-left-radius: 4px;
  box-shadow: 4rem 2rem 3px var(--gray-4);

  @media (min-width: 1441px) {
    position: inherit;
  }
  @media (max-width: 1440px) {
    max-height: 450px;
  }
`;
const Card = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  .btn-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    gap: 1rem;
    & button {
      text-transform: capitalize;
    }
  }
`;
const HowWorksElement = styled('div')`
  color: var(--gray-9);
  text-align: justify;

  ul {
    padding-left: 1rem;
  }

  @media (max-width: 991px) {
    width: 80%;
    margin-bottom: 10px;
  }

  @media (max-width: 576px) {
    width: 95%;
    padding-right: 0;
  }
`;
const HowWorksBackground = styled('div')`
  position: absolute;
  top: 0;
  left: -100vw;
  right: 5rem;
  bottom: 0;
  border-bottom-right-radius: 1rem;
  border-top-right-radius: 1rem;
  background-color: var(--gray-4);

  @media (max-width: 576px) {
    right: -0.5rem;
  }
`;
const ListItem = styled(Col)`
  display: flex;
  margin-bottom: 10px;

  svg {
    color: var(--primary);
    margin-right: 10px;
    font-size: 1.2rem;
  }
`;
const AdvantageListClaim = styled(Col)`
  position: inherit;
  display: flex;
  align-items: center;
  height: 240px;
  width: 100%;
`;
const AdvantageListBackground = styled('div')`
  position: absolute;
  height: 200px;
  width: 80%;
  position: absolute;
  right: 0;
  border-bottom-left-radius: 1rem;
  border-top-left-radius: 1rem;

  @media (min-width: 1200px) {
    width: 45%;
  }
`;
const AdvantageListClaimStatus = styled(Row)`
  background: #ffffff;
  box-shadow: 0px 2px 40px rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  padding: 1rem;
  width: 80%;
  z-index: 1;

  @media (max-width: 576px) {
    width: 100%;
  }
`;
const ProcessingInfo = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  span {
    color: var(--primary);
    border: solid 1px var(--primary);
    background-color: var(--primary-light);
    border-radius: 2px;
    text-align: center;
    padding: 2px 8px;
  }
`;
const ClaimStepsItem = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  position: relative;

  svg {
    color: var(--primary);
    margin-right: 10px;
    font-size: 32px;
  }

  .arrow {
    position: absolute;
    left: 0;

    svg {
      color: var(--gray-3);
      font-size: 1rem;
    }
  }

  .disabled-label {
    color: var(--gray-3);
  }

  .step-number {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 10px;

    &.active {
      background-color: var(--primary);
      border: solid 1px var(--primary);
      color: white;
    }

    &.disabled {
      color: var(--gray-3);
      border: solid 1px var(--gray-3);
    }
  }

  @media (max-width: 576px) {
    svg {
      font-size: 24px;
      margin-right: 5px;
    }

    .step-number {
      width: 24px;
      height: 24px;
      margin-right: 5px;
    }

    .arrow {
      display: none;
    }
  }
`;
const ClaimStepsBar = styled('div')`
  height: 1px;
  width: 100%;
  background-color: var(--gray-3);
  position: relative;

  div {
    background-color: var(--primary);
    position: absolute;
    left: 0;
    height: 2px;
    bottom: 0;
    width: 33%;
  }
`;
