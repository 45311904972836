import { KeyOutlined, MailOutlined } from '@ant-design/icons';
import { Col, Input, Row, Typography } from 'antd';
import AuthLayout from 'components/base/AuthLayout';
import useFormatMsg from 'hooks/translations/useFormatMsg';
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';

const { Text } = Typography;

function LoginForm({ onSubmit, loading }) {
  const { email: emailP } = useParams();
  const [email, setEmail] = useState(emailP || '');
  const [password, setPassword] = useState();
  const msg = useFormatMsg('auth.login');

  const actions = (
    <Row gutter={[0, 8]}>
      <Col span={24}>
        <Row justify="center">
          <Text type="secondary">
            {msg('notregisteryet')}{' '}
            <Link to="/register">{msg('register')}</Link>
          </Text>
        </Row>
      </Col>
      <Col span={24}>
        <Row justify="center">
          <Text type="secondary">
            <Link to="/forgot_password">{msg('forgotpassword')}</Link>
          </Text>
        </Row>
      </Col>
    </Row>
  );
  return (
    <>
      <AuthLayout
        title={msg('title')}
        caption={msg('caption')}
        actions={actions}
        btn={{
          label: msg('button'),
          onClick: () => onSubmit({ password, email }),
          loading,
        }}
      >
        <Row gutter={[0, 16]}>
          <Input
            name="email"
            size="large"
            placeholder={msg('placeholders.email')}
            prefix={<MailOutlined style={{ marginRight: '12px' }} />}
            type="text"
            value={email}
            onChange={event => {
              setEmail(event.target.value.trim());
            }}
          />
          <Input.Password
            name="password"
            size="large"
            placeholder={msg('placeholders.password')}
            prefix={<KeyOutlined style={{ marginRight: '12px' }} />}
            visibilityToggle
            onChange={event => {
              setPassword(event.target.value);
            }}
          />
        </Row>
      </AuthLayout>
    </>
  );
}

export default LoginForm;
